.welcomeSection {
  background: #fdf6f0;
  display: flex;

  .sectionContent {
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
  }

  .sectionText {
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
  }
  .welcome-second-section-img-body {
    width: 90%;
    height: 350px;
  }
  @media screen and (max-width: 479px) {
    .welcome-first-section {
      flex-direction: column-reverse;
    }
  }
}

.css-z8jjo0 {
  padding: 0px !important;
}

.css-e8m7e7 {
  margin-top: unset !important;
  margin-bottom: unset !important;
  transition: 0.8s;
}

.entry-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.intro-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.tutorial-video {
  width: 100%;
}

@media screen and (max-width: 479px) {
  .welcome-second-section-img-body {
    justify-content: center;
    display: flex;
    width: auto !important;
  }

  .welcome-second-section-button {
    padding-bottom: 30px !important;
  }
}
