@import '../../../assets//scss//mixins';

.textLink {
  display: flex;
  align-items: center;
  @include text-md;
  font-family: var(--chakra-fonts-third);
  color: var(--chakra-colors-gray-500);

  > svg {
    width: 20px;
    height: 20px;
    stroke: currentcolor;
    fill: none;
    flex: none;
    margin-right: var(--chakra-space-2);
  }

  @include addFocusBorder;

  &:hover {
    color: var(--chakra-colors-brand-500);
  }
}
