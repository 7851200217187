.chakra-button{
	flex: none;

	svg{
		flex: none;
		fill: none;
		stroke: currentcolor;
		width: 20px;
		height: 20px;
		
		&.size-sm{
			width: 10px;
			height: 10px;
		}
		
		&.size-2xl{
			width: 24px;
			height: 24px;
		}
	}
}