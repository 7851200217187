html {
  height: -webkit-fill-available;
  // LoadingModal container
  .css-z8jjo0 {
    height: 100vh;
  }
}

:root {
  --t: 0.3s;
  --header-height: 88px;
  --page-content-height: calc(100vh - var(--header-height));
}

@font-face {
  font-family: 'Ogg';
  src: url('../fonts/Ogg-Roman.woff') format('woff');
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

body {
  font-weight: var(--chakra-fontWeights-normal);
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

a,
button {
  transition-duration: var(--chakra-transition-duration-normal) !important;
}

a:hover {
  text-decoration: none !important;
}

#root > svg[display='none'] {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  visibility: hidden;
}

ul,
ol {
  list-style: none;
}

.wrapper {
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  height: 100vh;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fdf6f0;

  .pageContent {
    position: absolute;
    top: var(--header-height);
    width: 100%;
    height: calc(100% - var(--header-height));
  }
}

.popoverOverride {
  max-width: 40px;
  border-radius: 25px !important;
  background: #fdf6f0 !important;
  box-shadow: none !important;
  .openButton {
    padding: 0 !important;
    height: 35px;
  }
}

.openButton {
  background: #fdf6f0 !important;
  color: #8b3d5a;
  max-width: 40px;
  padding: 10px !important;
  border-radius: 25px !important;
  box-shadow: none !important;

  img {
    max-width: 20px;
    width: 100%;
  }

  svg {
    color: #8b3d5a;
  }
}

#chakra-modal-intro-video-modal{
  .chakra-modal__close-btn{
    svg{
      color: #fff;
    }
  }
  }

@import 'sliders';
@import 'buttons';
@import 'inputs';
