@import '../../../assets/scss/mixins';

.mobileNavWrapper {
  max-height: var(--page-content-height);
  overflow-y: auto;
  overscroll-behavior-y: contain;
  padding-bottom: 80px;
}

.mobileNav {
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--chakra-colors-gray-50);
}

.textLink {
  display: flex;
  align-items: center;
  @include text-md;
  font-family: var(--chakra-fonts-third);
  color: var(--chakra-colors-gray-900);

  > svg {
    width: 1.5rem;
    height: 1.5rem;
    stroke: var(--chakra-colors-brand-600);
    fill: none;
    flex: none;
    margin-right: var(--chakra-space-4);
  }

  @include addFocusBorder;

  &:hover {
    color: var(--chakra-colors-brand-500);
  }
}

.navItem {
  width: 100%;
  padding: var(--chakra-space-3) var(--chakra-space-4);

  .countBadge {
    min-height: 1.5rem;
    border-radius: 0.75rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 9px;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--chakra-fonts-third);
    color: var(--chakra-colors-gray-700);
    margin-left: 0.5rem;
    background-color: var(--chakra-colors-gray-100);

    &:before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      background: var(--chakra-colors-gray-500);
      flex: none;
      margin-right: 7px;
      border-radius: 50%;
    }
  }
}

.mobileNavFooterLinks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.75rem 1.5rem;
  padding: 1.5rem 1rem;

  .textLink {
    color: var(--chakra-colors-brand-700);
  }
}

.logOutBtn {
  color: var(--chakra-colors-gray-500);
  padding: 0.5rem;

  > svg {
    width: 1.25rem;
    height: 1.25rem;
    stroke: currentColor;
    fill: none;
    flex: none;
  }
}
