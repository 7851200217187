@import '../../../assets/scss/mixins';

.logoBlock {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 1.2;
  color: black;
  font-family: var(--chakra-fonts-heading);

  &:hover {
    color: var(--chakra-colors-brand-600);
  }

  @include addFocusBorder;
}
