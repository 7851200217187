// A11y
@mixin addFocusBorder($size: 8px, $radius: var(--chakra-radii-lg)) {
  position: relative;
  z-index: 2;

  &:focus-visible {
    outline: none;
    box-shadow: none;

    &:before {
      content: '';
      display: block;
      width: calc(100% + #{$size});
      height: calc(100% + #{$size});
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      box-shadow: var(--chakra-shadows-outline);
      border-radius: $radius;
    }
  }
}

// Typography
@mixin text-md {
  font-size: var(--chakra-fontSizes-md);
  font-weight: var(--chakra-fontWeights-medium);
  line-height: var(--chakra-lineHeights-base);
}
