.HHIcon{
	display: flex;
	align-items: center;
	justify-content: center;

	> svg{
		width: 20px;
		height: 20px;
		flex: none;
		fill: none;
		stroke: currentcolor;
	}
}