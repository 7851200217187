.note-card {
  width: 15em;
  height: 10.5em;
  padding: 0.3em;
  background-color: #fef4f2;
  position: absolute;
  color: #7e2448;
}

.sticky-notes-container {
  position: absolute;
}

.sticky-notes-container-active {
  position: absolute;
  z-index: 1;
}

.note-card {
  img {
    position: relative;
    width: 1.5em;
    height: 1.5em;
  }

  .toolbar-icon-button {
    background: none;
    border: none;
    padding: 0;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .toolbar-icon-button.active {
    svg {
      color: black;
    }
  }

  .btn {
    svg {
      padding-right: 5px;
    }
  }

  .note-body {
    font-family: 'Noto Sans', sans-serif;
    font-size: 13px;
  }

  .note-header {
    font-size: 1.25rem;
    font-weight: bold;
  }

  .note-header-container {
    height: 2.75em;
  }
}

.note-create-button {
  z-index: 5;
  position: absolute;
  bottom: 0.25em;
  right: 0.25em;
}

.icon-button.note-create-button {
  svg {
    color: #fef4f2;
    height: 2em;
    width: 2em;
  }
}

.note-footer {
  width: 95%;
  position: fixed;
  right: 5px;
  bottom: 5px;
}

.reminder-text {
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;

  &:hover {
    color: #7e2448;
  }
}
