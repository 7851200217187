.slick-slider {
  width: calc(100%);
  margin: -8px;
  left: 8px;

  .slick-slide {
    padding: 8px;
    height: inherit !important;

    > div {
      height: 100%;
    }
  }

  .slick-track {
    display: flex !important;
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 24px !important;
}

ul.slick-dots {
  bottom: -24px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;

  li {
    margin: 0 6px;
    width: 8px;
    height: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 100%;
      height: 100%;
      background: var(--chakra-colors-brand-100);
      padding: 0;
      opacity: 1;
      transition: all var(--t);
      border-radius: 50%;
      border: 0;

      &:before {
        display: none;
      }

      &:hover {
        opacity: 1;
        background: var(--chakra-colors-brand-300);
      }
    }

    &.slick-active {
      button {
        opacity: 1;
        background: var(--chakra-colors-brand-700);
      }
    }
  }
}
