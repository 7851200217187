.header {
  position: fixed;
  width: 100%;
  z-index: 100;
  background: var(--chakra-colors-white);
}

.headerContainer {
  @media screen and (max-width: 767px) {
    padding-left: 27px;
    padding-right: 27px;
  }
}

.logOutBtn {
  color: var(--chakra-colors-gray-500);
  padding: 0.5rem;

  > svg {
    width: 1.25rem;
    height: 1.25rem;
    stroke: currentColor;
    fill: none;
    flex: none;
  }
}
