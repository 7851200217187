.IconInCircles {
  width: 56px;
  height: 56px;
  border: 8px solid var(--chakra-colors-brand-50);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--chakra-colors-brand-600);
  background: var(--chakra-colors-brand-100);

  > svg {
    width: 24px;
    height: 24px;
    flex: none;
    fill: none;
    stroke: currentcolor;
  }
}

.themeSuccess {
  @extend .IconInCircles;

  border-color: var(--chakra-colors-success-50);
  background: var(--chakra-colors-success-100);
  color: var(--chakra-colors-success-600);
}

.themeWarning {
  @extend .IconInCircles;

  border-color: var(--chakra-colors-warning-50);
  background: var(--chakra-colors-warning-100);
  color: var(--chakra-colors-warning-600);
}
.themeNoColor {
  @extend .IconInCircles;

  border-color: var(--chakra-colors-gray-50);
  background: var(--chakra-colors-gray-100);
  color: var(--chakra-colors-gray-600);
}

.themePrimary {
  @extend .IconInCircles;

  border-color: var(--chakra-colors-primary-50);
  background: var(--chakra-colors-primary-100);
  color: var(--chakra-colors-primary-600);
  width: 50px;
  height: 50px;
}
